import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppActionsService {

  speech = new SpeechSynthesisUtterance();


  userAlreadyLogged: ReplaySubject<any> = new ReplaySubject(1);
  loggedIn: ReplaySubject<any> = new ReplaySubject(1);



  usersFetched:  ReplaySubject<any> = new ReplaySubject(1);
  userAdded:  ReplaySubject<any> = new ReplaySubject(1);
  userChanged:  ReplaySubject<any> = new ReplaySubject(1);
  userRemoved:  ReplaySubject<any> = new ReplaySubject(1);

  ticketsFetched: ReplaySubject<any> = new ReplaySubject(1);
  ticketRemoved: ReplaySubject<any> = new ReplaySubject(1);
  ticketChanged: ReplaySubject<any> = new ReplaySubject(1);
  ticketAdded:  ReplaySubject<any> = new ReplaySubject(1);

  constructor() {
    this.speech.lang = 'es';
  }

  speak(msg) {

    if (typeof msg == 'string') {
      this.speech.text = msg;
      //window.speechSynthesis.speak(this.speech);
    }

  }
}
