import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable()
export class ManagedInterceptor implements HttpInterceptor {

  constructor(private dataService: DataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("INTERCEPTOR request URL "+request.url);
    if (
        request.url.includes("api_jsonrpc") ||
        request.url.includes("firebase-access-api") ||
        request.url.includes("users") ||
        request.url.includes("projects") ||
        request.url.includes("tickets") ||
        request.url.includes("node/supervisor/RPC2") ||
        request.url.includes("bimshow")
      ) {
      return from( this.dataService.getToken()).pipe(
            switchMap (
              token => {
                  request = request.clone({
                      setHeaders: {
                        Authorization: 'Bearer '+token
                      }
                  });
                  // console.log("authorization token sent : "+token);
                  return next.handle(request);
              }
            )
          );
    }
    else {
      return next.handle(request);
    }
  }
}
