
<div class='card'>

  <div class="filters">
    <div class="filter">
      <div class="label">Status:</div>
      <select [(ngModel)]="filters.status" (change)="updateTicketsTableData()">
        <option [value]="'all'">all</option>
        <option [value]="'open'">open</option>
        <option [value]="'close'">close</option>
      </select>
    </div>
    <div class="filter">
      <div class="label">ticketId:</div>
      <input [(ngModel)]="filters.ticketId" (input)="updateTicketsTableData()">
    </div>

    <div class="filter">
      <div class="label">userId:</div>
      <input [(ngModel)]="filters.createdBy" (input)="updateTicketsTableData()">
    </div>
    
    
    <div *ngIf="loadingTickets">
      <span>Loading tickets...</span>
    </div>

  </div>
  <ngx-datatable [rows]="filteredTickets | async" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
    [limit]="10" rowHeight="auto" [sorts]="[{ prop: 'status', dir: 'desc' },{ prop: 'date', dir: 'desc' }]">
    <ngx-datatable-column prop="createdDate" name="Creation date" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="type" name="Type" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="title" name="Title" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="lastEvent" name="Last Event" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date:'yyyy/MM/dd HH:mm' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="status" name="Status" [width]="100">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="id" name="Actions" [width]="100" [sortable]="false">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <button mat-raised-button (click)="openDetailsDialog(value)">Details</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>