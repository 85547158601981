import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { TicketsService } from '../services/tickets.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  tickets = {};
  filteredTickets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);



  loadingTickets: boolean = true;


  filters = {
    status:'open',
    createdBy:null,
    ticketId:null
  };

  constructor(
    private appActionsService:AppActionsService,
    private dataService: DataService,
    private ticketsService: TicketsService,
    public dialog: MatDialog) {}

  ngOnInit(): void {

    this.appActionsService.ticketsFetched.subscribe(()=>{
      this.initTickets();
      this.loadingTickets = false;
    })
  }

  initTickets() {

    this.tickets = this.dataService.tickets;

    this.appActionsService.ticketChanged.subscribe(()=>{
      this.updateTicketsTableData();
    })
    this.appActionsService.ticketRemoved.subscribe(()=>{
      this.updateTicketsTableData();
    })
    this.appActionsService.ticketAdded.subscribe(()=>{
      this.updateTicketsTableData();
    })

    this.updateTicketsTableData();

  }

  updateTicketsTableData() {
    let filteredTickets = [];
    console.time('filter tickets')
    for(let ticketId in this.tickets) {
      const ticket = this.tickets[ticketId];
      
      if(this.filters.ticketId) {
        if(!ticketId.includes(this.filters.ticketId)) {
          continue;
        }
      }

      if(this.filters.createdBy) {
        if(!ticket.createdBy.includes(this.filters.createdBy)) {
          continue;
        }
      }

      if(this.filters.status !== 'all') {
    
        
        if(!ticket.status.includes(this.filters.status)) {
          continue;
        }
      }

      filteredTickets.push(ticket)
    }
    
    
    console.timeEnd('filter tickets')
    this.filteredTickets.next(filteredTickets)
  }

 
  setTicketStatus(id,status) {
    this.ticketsService.setTicketStatus(id, status)
      .subscribe(
        result => {
          this.initTickets();
        },
        error => {
          console.log("error set ticket status: "+error.message)
        }
      );
  }



 openDetailsDialog(ticketId) {
   console.log("openDetailsDialog : ticketId "+ticketId);
   const data = {
     data: {
       ticketId : ticketId
     },
     height: '800px',
     width: '800px'
   }
   const dialogRef = this.dialog.open(TicketDetailsDialog,data);

   dialogRef.afterClosed().subscribe(
     dialog_result => {
        this.initTickets();
      }
    );
  }
}

/*
 * for modal popup
 */
@Component({
  selector: 'tickets-detail',
  templateUrl: 'tickets-detail.html',
  styleUrls: ['tickets-detail.scss']
})
export class TicketDetailsDialog {

  ticket: any = {};
  events: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  setAddEventRunning: boolean = false;
  newEventDatas: any = {};
  newStatus: string;

  constructor(
    private _ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data,
    private ticketsService: TicketsService) {
    this.newEventDatas = {
      content: null,
      createdBy: "support"
    }
    this.getTicket(data.ticketId);
    this.getTicketEvents(data.ticketId);


    this.events.subscribe(
      state => {
        setTimeout(() => {
          this.table.rowDetail.expandAllRows();
        }, 2000);
      }
    );

  }

  addEvent() {
    this.setAddEventRunning = true;
    this.ticketsService.addEventToTicket(this.ticket.id, JSON.parse(JSON.stringify(this.newEventDatas)))
      .subscribe(
        event_result => {
          this.setAddEventRunning = false;
          this.getTicketEvents(this.ticket.id);
        },
        error => {
          console.log("error open ticket: "+error.message)
          this.setAddEventRunning = false
        }
      );
      this.newEventDatas.content = null;
  }
  getTicket(ticketId) {
    this.ticketsService.getTicket(ticketId).toPromise()
      .then(
        ticket => {
          this.ticket = ticket.content;
          this.newStatus = ticket.content.status;
        }
      )
      .catch(
        error => {
          console.log("error gettting ticket");
        }
      );
  }
  setTicketStatus() {
    this.ticketsService.setTicketStatus(this.ticket.id, this.newStatus)
      .subscribe(
        result => {
          console.log('ok set ticket status')
          this.getTicketEvents(this.ticket.id);
        },
        error => {
          console.log("error set ticket status: "+error.message)
        }
      );
  }
  getTicketEvents(id) {
    this.ticketsService.getTicketEvents(id).toPromise()
      .then(
        events => {
          var eventsToPush = [];
          for (const event in events.content){
            eventsToPush.push(events.content[event]);
          }
          this.events.next(eventsToPush);
        }
      )
      .catch(
        error => {
          console.log("error getting ticket events");
        }
      );
  }

  handleFile(files: FileList){
    //this.data.upload_file = files.item(0);
  }

  //for textarea dynamic height
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  //for detail expand
  @ViewChild('myTable') table: any;
  expanded: any = {};
  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {}
}
