// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloundFunctionsHost: "https://europe-west3-bimshow-dev1.cloudfunctions.net",
  // cloundFunctionsHost: "http://127.0.0.1:5001/bimshow-dev1/europe-west3",
  bimshow_url: 'https://dev1.bimshow.io/',
  firebaseConfig: {
    apiKey: "AIzaSyBVzudmKVGnE3Lmgyuttx2NF7zhG0Q0ShA",
    authDomain: "bimshow-dev1.firebaseapp.com",
    databaseURL: "https://bimshow-dev1.firebaseio.com",
    projectId: "bimshow-dev1",
    storageBucket: "bimshow-dev1.appspot.com",
    messagingSenderId: "337558794368",
    appId: "1:337558794368:web:9829c299c9c46af1f8b665"
  },
  claim_service_host: 'dev1.bimshow.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
