import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {


  constructor(private http: HttpClient, private dataService: DataService) { }

  /*to handle http error*/
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  openTicket(datas): Observable<any> {
    return this.http.post<any>(this.dataService.cloudFunctionHost + '/openNewTicket', datas, { responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  getTicketsUsers(id): Observable<any> {
    return this.http.get(this.dataService.cloudFunctionHost + "/getUserTickets", { params: { userId: id }, responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  getTicket(id): Observable<any> {
    return this.http.get(this.dataService.cloudFunctionHost + "/getTicket", { params: { id: id }, responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  setTicketStatus(id, status): Observable<any> {
    return this.http.post<any>(this.dataService.cloudFunctionHost + "/updateTicketsStatus", { status, id }, { responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  addEventToTicket(id, datas): Observable<any> {
    datas['ticketId'] = id;
    return this.http.post<any>(this.dataService.cloudFunctionHost + "/addTicketEvent", datas, { responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  getTicketsOpen(): Observable<any> {
    return this.http.get(this.dataService.cloudFunctionHost + "/getAllOpenTickets", { responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }
  getTicketEvents(id): Observable<any> {
    return this.http.get(this.dataService.cloudFunctionHost + "/getTicketEvents", { params: { id }, responseType: 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }

}
